import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import FooterLogo from 'assets/images/logo-footer.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Choice One Savings Plan',
  tokenName: 'choiceoneSubscriberToken',
  globalClass: 'choiceone-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: true,
    aboutUs: { useWebsiteLink: true },
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButton: 'Login',
      secondaryButtonProps: {
        to: '/login',
      },
      background: '/images/home-images/home-banner-img.webp',
      alignContent: 'center'
    },
    benefitsSection: {
      variant: 4,
      columns: 3,
      alignCard: 'left',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      planCardProps: {
        buttonProps: {
          highlightedColor: 'primary',
          highlightedVariant: 'contained',
        },
      },
    },
    howItWorksSection: {
      variant: 2,
      alignTitle: 'left',
      heroImage: '/images/home-images/how-it-works.webp',
    },
    testimonialSection: {
      variant: 2,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 9,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
  },
  checkoutSection: {},
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly',
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: false,
  },
  savingsSummary: {
    enableFooterbar: true,
    showCategoryHeaders: true,
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'secondary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {
    enableBookAppointment: false,
  },
  footer: {
    enable: true,
    logo: FooterLogo,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
