export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#10B7F2',
    contrastText: '#FFFFFF',
    light: '#F1FAFE',
  },
  secondary: {
    main: '#FFFFF',
    contrastText: '#002855',
  },
  text: {
    primary: '#111111',
    light: 'rgba(71, 86, 112, 0.8)',
    secondary: '#5E6C77',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#26364D',
    primary: '#10B7F2',
    paper: '#FFF',
    default: '#FFF',
    header: '#26364D',
    footer: '#374E6F',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#26364D',
    tableSubhead: '#F1FAFE',
    arrow: '#10B7F2',
    tableAlternate: '#F8F8F8',
    alternate: '#E3F1FD',
    success: '#DBEFDC',
    error: '#FFEAEA',
    secondary: '#F8D859',
  },
  verticalBar: {
    used: '#10B7F2',
    unused: '#F1FAFE',
    unlimited: '#C0C0C0',
  }
};
